"use client";

import React, { useEffect, useState } from "react";

const ThemeToggle = ({ onThemeChange = () => {} }) => {
  const [isDark, setIsDark] = useState(true);
  const [isAnimating, setIsAnimating] = useState(false);

  const handleToggle = () => {
    setIsAnimating(true);
    setIsDark(!isDark);
    onThemeChange(!isDark);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      document.body.dataset.mode = !isDark ? "light" : "dark";
    }
  }, [isDark]);

  return (
    <button
      onClick={handleToggle}
      className={`relative w-20 h-10 top-1 rounded-full p-1 transition-colors duration-500 ease-in-out ${
        isDark ? "bg-foreground" : "bg-foreground"
      }`}
      aria-label={`Switch to ${isDark ? "light" : "dark"} mode`}
    >
      <div
        className={`
          absolute top-1
          w-8 h-8 rounded-full
          ${isDark ? "bg-background" : "bg-background"}
          transition-all duration-500 ease-in-out
          flex items-center justify-center
          transform-gpu
          ${isDark ? "translate-x-10" : "translate-x-0"}
          ${isAnimating ? "scale-90" : "scale-100"}
        `}
        onTransitionEnd={() => setIsAnimating(false)}
      >
        <div
          className={`
          absolute inset-0 flex items-center justify-center
          transition-opacity duration-500
          ${isAnimating ? "opacity-0" : "opacity-100"}
        `}
        >
          {isDark ? (
            <svg
              viewBox="0 0 24 24"
              className="w-6 h-6 text-slate-700"
              fill="none"
              stroke="white"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                className="animate-draw"
              />
            </svg>
          ) : (
            <svg
              viewBox="0 0 24 24"
              className="w-6 h-6 text-yellow-500"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
                className="animate-draw"
              />
            </svg>
          )}
        </div>
      </div>
    </button>
  );
};

export default ThemeToggle;
