"use client";

import SmoothScroll from "@/components/common/SmoothScroll";
import { GoogleAnalytics } from "@next/third-parties/google";

export default function Providers({ children }: { children: React.ReactNode }) {
  return (
    <>
      <SmoothScroll>{children}</SmoothScroll>
      <GoogleAnalytics gaId="G-CYHR6WHWTT" />
    </>
  );
}
